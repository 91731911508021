class ContentImage {
	/**
	 * Create and initialise objects of this class
	 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes/constructor
	 * @param {object} block
	 */
	constructor() {
		this.blocks = document.querySelectorAll('.block-example');
		this.init();
	}

	/**
	 * Example function to run class logic
	 * Can access `this.block`
	 */
	init() {
		this.blocks.forEach((block) => {
			console.log("🚀 ~ file: Example.js ~ line 20 ~ Example ~ init ~ this.block", block);
		});
	}
}

new ContentImage();
